import React from 'react'
import LOGO from '../../assets/logo.png'
import css from '../Footer/Footer.module.css'
import {
    PhoneIcon,
    InboxIcon,
    LinkIcon,
    LoginIcon,
    UserIcon,
    LocationMarkerIcon,
} from '@heroicons/react/outline';

const Footer = () => {
  return (
    <div className={css.cFooterWrapper}>
     <hr/>

     <div className={css.cFooter}>
        <div className={css.logo}>
            <img src={LOGO} alt="" />
       
       <span>Amazon</span>

        </div>


        <div className={css.block}>
          <div className={css.detail}>
            <span>Contact Us</span>
<span className={css.pngLine}>
  <LocationMarkerIcon className={css.icon}/>
  <span>111 northavenue orlando, FL 2801</span>
</span>

 
<span className={css.pngLine}>
{""}
<PhoneIcon className={css.icon}/>

<a href="tel:352-306-4415">352-306-4415</a>

</span>

<span className={css.pngLine}>
 
<InboxIcon className={css.icon}/>

<a href="mailto:suppport@amazon.com">suppport@amazon.com</a>
</span>

          </div>
        </div>


        <div className={css.block}>
          <div className={css.detail}>
            <span>Account</span>
<span className={css.pngLine}>
  <LoginIcon className={css.icon}/>
Sign In
</span>
</div>
        </div>


        <div className={css.block}>
          <div className={css.detail}>
            <span>Company</span>
<span className={css.pngLine}>
  <UserIcon className={css.icon}/>
  <a href="/about">
    <p>About us</p>
  </a>
  </span>
</div>
          </div>



          <div className={css.block}>
          <div className={css.detail}>
            <span>Resources</span>
<span className={css.pngLine}>
  <LinkIcon className={css.icon}/>
  
    <p>Safety Privacy & Terms</p>
 
  </span>
</div>
          </div>
    
     </div>



<div className={css.CopyRight}>
  <span>Copyright 2022 by Amazon, Inc.</span>
  <span>All right reserved</span>
</div>




      </div>
    
   
  )
}

export default Footer