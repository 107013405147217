import React from 'react'
import css from '../Hero/Hero.module.css'
import HeroImg from '../../assets/hero.png'
import {RiShoppingBagFill} from 'react-icons/ri'
import {BsArrowRight} from 'react-icons/bs'
import {motion} from 'framer-motion'


const Hero = () => {
  return (
    <div className={css.container}>
      <div className={css.h_sides}>
        <span className={css.text1}>
          skin protection cream
        </span>
        
<div className={css.text2}>

  <span>
    Trendy Collection
  </span>

  <span>
    {""}
    Seedily say has suitable disposal and boy. Exercise joy man children rejoiced.
  </span>
</div>

      </div>

<div className={css.wrapper}>
  <motion.div 

animate={{ y: -30, scale: 1.1}}
transition={{ ease: "easeOut", duration: 3 }}
  

  className={css.blueCircle}
 
 
 ></motion.div>


    <motion.img
 
 
animate={{ y: 30,}}
transition={{ ease: "easeOut", duration: 3 }}
  
    src={HeroImg} alt="" width={600} />



  <motion.div 
animate={{y:30, x: 50,}}
transition={{ ease: "easeOut", duration: 3 }}
  className={css.cart2}>
    <RiShoppingBagFill/>
    <div className={css.signup}>
      <span>
        Best Signup Offers
      
      </span>
<div className={css.arrowright}>

  <BsArrowRight/>

</div>

    </div>


    </motion.div>
  
  </div>


  <div className={css.h_sides}>
    <div className={css.traffic}>
      <span>1.5m</span>
      <span>Monthy Traffic</span>

    </div>
<div className={css.customers}>
  <span>100k

  </span>

  <span>Happy Custommers</span>
</div>


  </div>
</div>


 






  )
}

export default Hero