import "./App.css";
import Header from "./componets/Header/Header"
import Footer from "./componets/Footer/Footer";
import Hero from "./componets/Hero/Hero";
import Products from "./componets/Products/Products";
import Slider from "./componets/Slider/Slider";
import Testimonials from "./componets/Testimonials/Testimonials";
 import  Virtual  from "./componets/Virtual/Virtual";

function App() {
  return (
    <div className="App">
         <Header/>
  <Hero/>
  <Slider/>
  <Virtual/>
  <Products/>
  <Testimonials/>
  <Footer/>
    </div>
  );
}

export default App;
