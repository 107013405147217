import React from 'react'
import css from "../Testimonials/Testimonial.module.css"
import {TestimonialsData} from '../../data/testimonials'
import Hero from "../../assets/testimonialHero.png"
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'


const Testimonials = () => {

  return (
    <div className={css.testimonials}>
        
        <div className={css.wrapper}>
          
<div className={css.container}>
   
           <span>Top Rated</span><span>
            Seedily say has suitable disposal and boy.Exercise joy man children rejoiced </span> 
            </div>

       <img src={Hero} alt="" />

<div className={css.container}>
    <span>100k</span>
    <span>Happy Customs with us </span>
</div>
</div>
<div className={css.review}>

  <hr width="80%" color="white"
        size="2"></hr>
</div>


<div className={css.reviews}>Reviews</div>

 
<div className={css.carousal}>

    <Swiper
   
    slidesPerView={3}
    slidesPerGroup={1}
    spaceBetween={30}
    className={css.tCarousal}
    breakpoints={{
      0: {
        
        slidesPerView: 1,
      },
      640: {
       
        slidesPerView: 2,
      },
      768: {
        
        slidesPerView: 3,
      },
      1200: {
        
        slidesPerView: 3,
      }
    }}
  
  >

  


{TestimonialsData.map((testimonial, i)=>(
    <SwiperSlide>
<div className={css.testimonial}>
<img src={testimonial.image} alt="" />
<span> {testimonial.comment} </span>


<hr />
<span> {testimonial.name}</span>
</div>

    </SwiperSlide>
  ))
}
    </Swiper>
</div>

</div> 

  
  )
}

export default Testimonials